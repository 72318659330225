export const animationDurationMs = 320;
export const delayAfterReopeningMs = 100;

/** @type {HTMLElement | null} */
const mainEl = document.querySelector("#js-main-container");
/** @type {HTMLElement | null} */
const skipLinksEl = document.querySelector("#js-skip-links");
/** @type {HTMLElement | null} */
const firstBodyElement = document.querySelector("#js-first-element");

/**
 * @param {HTMLElement} [modalEl]
 */
export const onModalOpen = async (modalEl) => {
	if (mainEl) mainEl.inert = true;
	if (skipLinksEl) skipLinksEl.inert = true;

	if (!modalEl) return;
	await new Promise((resolve) => window.setTimeout(resolve, 60));
	const dialogEl = modalEl.querySelector("[role=dialog]");
	dialogEl?.focus();
};

export const onModalClosed = async (triggerEl = firstBodyElement) => {
	if (mainEl) mainEl.inert = false;
	if (skipLinksEl) skipLinksEl.inert = false;

	if (triggerEl === null) return;
	await new Promise((resolve) => window.setTimeout(resolve, 60));
	triggerEl.focus();
};
