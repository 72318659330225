import { ref, Ref, computed } from "vue";

export type DepartmentType = "damen" | "herren" | "kinder";

type UseDepartment = {
	department: Ref<string>;
	departmentCapitalized: Readonly<Ref<string>>;
};

const department = ref(window.ocm?.config?.department as DepartmentType);

const _capitalize = (value: string): string => (!value ? "" : value.charAt(0).toUpperCase() + value.slice(1));
const departmentCapitalized = computed(() => _capitalize(department.value));

export default function useDepartment(): UseDepartment {
	return {
		department,
		departmentCapitalized,
	};
}
