/**
 * Google Analytics v4
 * see https://redbox.outletcity.com/confluence/display/TUB/Google+Analytics+4+%28GA4%29+E-Commerce+Tracking
 */

import pushDataLayer from "./dataLayer";
import type { EventName, GA4ItemCollectionPayload, GA4ItemPayload } from "../types/GA4Types.type";

export const pushGA4Event = (name: EventName, payload: GA4ItemPayload | GA4ItemCollectionPayload) => {
	const event = {
		event: name,
		ga4_ecommerce: payload,
	};
	pushDataLayer(event, { clearEcommerceObject: true });
};

export const pushGA4CustomEvent = (name: string, payload: Record<string, unknown>) => {
	const event = {
		event: name,
		category: undefined,
		action: undefined,
		label: undefined,
		attributes: undefined,
		type: undefined,
		...payload,
	};
	pushDataLayer(event);
};
