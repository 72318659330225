import { ref, Ref, computed } from "vue";
import useDepartment from "./use-department";
import { contextPath } from "@ocm/services/ocm-object";

type useMostSuggestedRequest = {
	mostSuggestedItems: Readonly<Ref<MostSuggestedItemsResult>>;
	hasMostSuggestedItems: Readonly<Ref<boolean>>;
	loadSuggestions: () => Promise<void>;
};

type MostSuggestedItemsResult = {
	headline: string;
	mostSearchItems: Array<{
		url: string;
		linkName: string;
		sameWindow: boolean;
	}>;
};

const mostSuggestedItems: Ref<MostSuggestedItemsResult> = ref({
	headline: "",
	mostSearchItems: [],
});
const hasMostSuggestedItems = computed((): boolean => mostSuggestedItems.value.mostSearchItems.length > 0);

const loadSuggestions = async (): Promise<void> => {
	const { department } = useDepartment();
	let url = `${contextPath}/rest/search/config/`;
	if (department.value !== null && department.value !== "") url += `?department=${department.value}`;

	try {
		const response = await fetch(url);
		if (!response.ok) throw new Error("Most Suggested request failed");

		const data: MostSuggestedItemsResult = await response.json();
		mostSuggestedItems.value = data;
	} catch (err: unknown) {
		console.error(err);
	}
};

export default function useMostSuggestedRequest(): useMostSuggestedRequest {
	return {
		mostSuggestedItems,
		hasMostSuggestedItems,
		loadSuggestions,
	};
}
