import { computed, Ref, ref, nextTick } from "vue";
import fetchJsonp from "fetch-jsonp";
import { useDebounceFn } from "@vueuse/core";
import { useTracking } from "./use-tracking";
import { contextPath, isClassicSite } from "@ocm/services/ocm-object";
import useDepartment from "./use-department";
import useMostSuggestedRequest from "./use-most-suggested-request";
import useFindings from "./use-findings";
import { getOcmRequestUrl } from "@/services/ocm-request-settings";
import { getEpoqRequestParams, epoqUrl, epoqFallbackUrl, epoqTimeout } from "@/services/epoq-request-settings";
import { EpoqResult } from "@/types/epoq.type";

type OcmResult = {
	typeAheadItems: TypeAheadItem[];
};
type TypeAheadItem = {
	entries: [
		{
			content: string;
			link: string;
		}
	];
	headline: string;
};

export const typeAheadItems = ref<TypeAheadItem[]>([]);
export const hasTypeAheadItems = computed(() => typeAheadItems.value.length > 0);

type UseSearch = {
	isLoading: Readonly<Ref<boolean>>;
	requestFinished: Readonly<Ref<boolean>>;
	setUpRequest: () => void;
	userInput: Ref<string>;
	userInputMaxlength: number;
	setInputElementRef: (ref: HTMLInputElement | null) => void;
	isSearchVisible: Readonly<Ref<boolean>>;
	isSearchMostSuggested: Readonly<Ref<boolean>>;
	openSearch: () => void;
	closeSearch: () => void;
	focusSearch: () => void;
	deleteSearch: () => void;
	triggerSearch: (trigger?: "voice" | "click" | "keyboard") => void;
	triggerTypeAhead: () => void;
	resetSearch: () => void;
	setUserInput: (value: string) => void;
};

const requestFinished = ref(true);
const isLoading = ref(false);
const _setLoading = () => {
	if (!requestFinished.value) isLoading.value = true;
};
const _debounceLoading: () => void = useDebounceFn(_setLoading, 150);
const _setRequestFinished = () => {
	requestFinished.value = true;
	isLoading.value = false;
};

const userInput = ref("");
const userInputMaxlength = 150;
// android fix for not updated v-model value on input TUB-16479
const setUserInput = (newInput: string) => {
	userInput.value = newInput;
};
let searchQuery = "";
const isSearchMostSuggested = computed(() => userInput.value.length < 3);
const isSearchVisible = ref(false);

const _ocmRequest = async () => {
	const requestUrl = getOcmRequestUrl(searchQuery);
	try {
		const response = await fetch(requestUrl);
		if (!response.ok) throw new Error("OCM TypeAhead request failed");

		const data: OcmResult = await response.json();
		typeAheadItems.value = data.typeAheadItems.filter((item) => item.entries.length > 0);
	} catch (err: unknown) {
		console.error(err);
	}
};

const { setFindings } = useFindings();
const _epoqRequest = async (requestUrl: string) => {
	const requestParams = getEpoqRequestParams(searchQuery);
	const jsonpUrl = new URL(`${requestUrl}?${new URLSearchParams(requestParams)}`).toString();
	requestFinished.value = false;
	_debounceLoading();

	try {
		const response = await fetchJsonp(jsonpUrl);
		if (!response.ok) throw new Error("Epoq Search request failed");

		const { result }: { result: EpoqResult } = await response.json();
		setFindings(result);
	} catch (err: unknown) {
		console.error(err);
	} finally {
		_setRequestFinished();
	}
};

let useFallback = false;
const _epoqRequestTimeout = () =>
	new Promise((_, reject) => {
		setTimeout(() => {
			reject(new Error("Epoq Search request timed-out"));
		}, epoqTimeout);
	});
const _epoqRequestWithFallback = async () => {
	try {
		await Promise.race([_epoqRequest(useFallback ? epoqFallbackUrl : epoqUrl), _epoqRequestTimeout()]);
	} catch (err: unknown) {
		console.error(err);
		if (useFallback) return;

		useFallback = true;
		// retry with fallback url
		_epoqRequestWithFallback();
		// reset to default url after 5 minutes
		setTimeout(() => {
			useFallback = false;
		}, 300000);
	}
};

const setUpRequest = () => {
	isClassicSite ? _ocmRequest() : _epoqRequestWithFallback();
};

const _debounceRequest: () => void = useDebounceFn(setUpRequest, 200);
const inputEl = ref<HTMLInputElement | null>(null);

const triggerTypeAhead = () => {
	if (!isSearchMostSuggested.value) {
		searchQuery = userInput.value;
		_debounceRequest();
	}
};

const focusSearch = () => {
	inputEl?.value?.focus();
};

const setInputElementRef = (ref: HTMLInputElement | null) => {
	inputEl.value = ref;
};

const resetSearch = () => {
	userInput.value = "";
	inputEl?.value?.blur();
	closeSearch();
};

const openSearch = () => {
	if (isSearchVisible.value) {
		focusSearch();
		return;
	}

	useMostSuggestedRequest().loadSuggestions();
	isSearchVisible.value = true;
	nextTick(() => {
		focusSearch();
	});
	triggerTypeAhead();
};

const closeSearch = () => {
	if (!isSearchVisible.value) return;
	isSearchVisible.value = false;
	inputEl?.value?.blur();
};

const deleteSearch = () => {
	userInput.value = "";
	focusSearch();
	triggerTypeAhead();
};

const _navigateToClassicSearchResult = (searchEncoded: string) => {
	const link = typeAheadItems.value?.[0]?.entries?.[0]?.link;
	if (userInput.value.length > 2 && link?.includes("labelId")) {
		window.location.assign(link);
	} else {
		window.location.assign(`${contextPath}/content-search/?text=${searchEncoded}`);
	}
};

const _navigateToShopSearchResult = (searchEncoded: string) => {
	const { departmentCapitalized, department } = useDepartment();
	const url = `${contextPath}/suche/?s=${searchEncoded}&department=${department.value}#/Kategorie/${departmentCapitalized.value}`;

	if (window.ocmOrchestrator) {
		window.ocmOrchestrator.spaNavigateUrl(url);
		resetSearch();
	} else {
		window.location.assign(url);
	}
};

enum triggerType {
	click = "textsearch",
	keyboard = "textsearch",
	voice = "voicesearch",
}
const { tr_searchEvent } = useTracking();
const triggerSearch = (trigger: "voice" | "click" | "keyboard" = "keyboard") => {
	if (userInput.value.length < 1) return;

	tr_searchEvent({ searchterm: userInput.value, type: triggerType[trigger] });

	const searchEncoded = encodeURIComponent(userInput.value.substring(0, userInputMaxlength).trim());
	isClassicSite ? _navigateToClassicSearchResult(searchEncoded) : _navigateToShopSearchResult(searchEncoded);

	if (window.EpoqNS) {
		console.log("%c%s", "color:#fff;background-color:#35495e;", "search: EpoqNS.epoqForeignInit");
		const stateUrl = window.location.href;
		window.EpoqNS.epoqForeignInit({ stateUrl });
	}
};

export default function useSearch(): UseSearch {
	return {
		isLoading,
		requestFinished,
		setUpRequest,
		userInput,
		userInputMaxlength,
		setInputElementRef,
		isSearchVisible,
		isSearchMostSuggested,
		openSearch,
		closeSearch,
		focusSearch,
		deleteSearch,
		triggerSearch,
		triggerTypeAhead,
		resetSearch,
		setUserInput,
	};
}
