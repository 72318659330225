export const country = window.ocm?.config?.country || "de";
export const language = window.ocm?.config?.language || "de";
export const contextPath = window.ocm?.config?.encodedContextPath ?? `/${country}-de/shop`;
export const isClassicSite = window.ocm?.isClassicSite ?? false;
export const shopContextPath = isClassicSite ? `/${country}-de/shop` : contextPath;

// Epoq
export const isEpoqRecosCartEnabled = window.ocm?.config?.isEpoqRecosCartEnabled;
export const epoqRecosPlpChunkSize = window.ocm?.config?.epoqRecosPlpChunkSize;
export const is8SelectRecosPdpEnabled = window.ocm?.config?.is8SelectRecosPdpEnabled;
export const isEpoqRecosPdpEnabled = window.ocm?.config?.isEpoqRecosPdpEnabled;

// Contact Center
export const captchaPublickey = window.ocm?.captchaOptions?.publickeyContactcenter || "";
export const captchaActive = window.ocm?.captchaOptions?.displayContactcenter;

export const department = window.ocm?.config?.department;

// TUB-19190 Cart Payment Buttons ABC-Test
export const isPaymentButtonsStickyAnchorButton = window.ocm?.config?.isPaymentButtonsStickyAnchorButton;
export const isPaymentButtonsNotSticky = window.ocm?.config?.isPaymentButtonsNotSticky;
export const isPaymentButtonsAllSticky = window.ocm?.config?.isPaymentButtonsAllSticky;

// TUB-20125 PLP Mobile Filter Optimization AB-Test
export const isMobileFilterOptimization = window.ocm?.config?.isMobileFilterOptimization ?? false;

// TUB-20368 PDP Sizekick Widget AB-Test
export const isSizekickEnabled = window.ocm?.config?.isSizekickEnabled ?? false;
