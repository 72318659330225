import { language, country } from "./ocm-object";

const locale = `${language}-${country.toUpperCase()}`;
const defaultCurrency = country === "ch" ? "CHF" : "EUR";

export const formatPrice = (price: number | string, currencyIso = defaultCurrency) => {
	const _locale = currencyIso === "CHF" ? "de-CH" : "de-AT";
	return new Intl.NumberFormat(_locale, { style: "currency", currency: currencyIso, useGrouping: false }).format(
		Number(price)
	);
};

export const formatDiscount = (percentage: number | string) => `-${Math.floor(Math.abs(Number(percentage)))}%`;

export const formatNumber = (number: number | string) => Number(number).toLocaleString(locale);
