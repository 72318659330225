/**
 * dataLayer
 *
 * push dataLayer
 *
 */

function _clearEcommerceObject() {
	/**
	 * It's recommended that you use the following command to clear the ecommerce object prior to pushing an ecommerce event to the data layer.
	 * Clearing the object will prevent multiple ecommerce events on a page from affecting each other.
	 */
	pushDataLayer({ ga4_ecommerce: null });
}

function pushDataLayer(obj, options = {}) {
	if (options.clearEcommerceObject) _clearEcommerceObject();

	window.ocm = window.ocm || {};
	if (!window.ocm.dataLayerHelper) return;
	window.ocm.dataLayerHelper.push(obj);
}

export default pushDataLayer;
