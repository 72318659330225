type EpoqConfig = {
	customerId: string;
	sessionId: string;
	tenantId: string;
};

export default function epoqConfig(): EpoqConfig {
	return {
		customerId: window.flowConfig?.customerId ?? window.epoq_customerId,
		sessionId: window.flowConfig?.sessionId ?? window.getEpoq_sessionId() ?? "1",
		tenantId: window.flowConfig?.tenantId ?? window.epoq_tenantId,
	};
}
