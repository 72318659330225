type UseScrollBehaviourBody = {
	lockBody: () => void;
	unlockBody: () => void;
};

const lockBody = (): void => {
	document.body.classList.add("overflow-hidden");
};

const unlockBody = (): void => {
	document.body.classList.remove("overflow-hidden");
};

export default function useScrollBehaviourBody(): UseScrollBehaviourBody {
	return {
		lockBody,
		unlockBody,
	};
}
