import { pushGA4CustomEvent } from "@ocm/services/analytics4Tracking";

export const useTracking = () => {
	const tr_searchEvent = (payload: Record<string, string>) => {
		pushGA4CustomEvent("search", payload);
	};

	return {
		tr_searchEvent,
	};
};
